import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import JsonEditor from 'components/JsonEditor';
import { deleteProfile, updateProfile } from 'delivery/services/profileService';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { vehicleParamsLabel as vpl } from 'utils/constants';
import { byId } from 'utils/utils';
import { a } from './planProfileReducer';
import DeckGL from '@deck.gl/react';
import ReactMapGL, { StaticMap } from 'react-map-gl';
import { PolygonLayer } from '@deck.gl/layers';

const animatedComponent = makeAnimated();
const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
  accordion: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
  accordionSummary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    height: '40px',
  },
  copyProfileButton: {
    marginLeft: 'auto',
    position: 'absolute',
    right: theme.spacing(2),
  },
  cardActionPanel: {
    position: 'relative',
  },
  name: {
    // marginLeft: theme.spacing(2),
  },
  description: {
    width: '70%',
  },
  heading: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 0,
  },
  selectControl: {
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 200,
  },
  // headingC: {
  //   flex: '0 0 200px',
  // },
  default: {},
  sectionHeading: {
    paddingBottom: theme.spacing(1),
  },
  section: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  vehicleLabel: {
    flex: '0 0 100px',
  },
  vehicleHeader: {
    paddingBottom: theme.spacing(1),
  },
  controlC: {
    // padding: theme.spacing(1),
  },
  constraintsSection: {
    marginTop: '20px',
  },
  facilitySection: {
    width: '250px',
  },
}));

const orderServiceTypeOptions = ['PICKUP', 'DROP'].map((x) => ({ value: x, label: x }));

const channelOptions = [
  { label: 'All Channels', value: 'allChannels' },
  { label: 'Big', value: 'big' },
  { label: 'Medium', value: 'medium' },
  { label: 'Wholesale', value: 'wholesale' },
];

export default function Profile({
  accordionId,
  profile,
  ui,
  salesman,
  apiAction,
  dispatch,
  isNew,
  createDialog,
  openDialog,
  disableDefault,
  profilesName,
}) {
  const classes = useStyles();
  const {
    id = 'new',
    name,
    description,
    autoLearning,
    isDummy = false,
    isMultiTrip = false,
    isInterleaved = false,
    sequence,
    default: def,
    static: isStatic,
    autoPlan = false,
    autoFreeze,
    routeOption,
    allSalesmen,
    serviceTimeModel,
    exclusiveZones,
    routingProfile,
    salesmanIds,
    valueFilter = '',
    allChannels,
    channelIds,
    useDelWindow = false,
    useVehCompat = false,
    planningLevel,
    orderPriorityCriteria,
    orderPrioritySortType,
    // deliveryServiceTime = {},
    // pickupServiceTime = {},
    facWorkingHrs,
    data,
    branchId,
    vehicleTypes,
    startTime,
    deliveryWeightConstraint,
    deliveryCapacityUnit,
    wave1Profile,
    serviceTypes,
  } = profile;

  const handleChange = useCallback(
    (key) => (value) => {
      dispatch([a.EDIT, { id, key, value }]);
    },
    [dispatch, id]
  );

  const deleteConfirmDialog = useRef();
  const salesmanFilterValues = useMemo(() => {
    let salesmanidsValues;
    if (allSalesmen) {
      salesmanidsValues = [
        {
          label: 'All Salesmen',
          value: 'allSalesmen',
        },
      ];
    } else {
      salesmanidsValues =
        salesman && salesman.length && salesmanIds
          ? salesmanIds
              .map((id) => byId(salesman, id) || {})
              .map(({ id, code }) => ({ label: code, value: id }))
          : [];
    }
    return salesmanidsValues;
  }, [salesman, salesmanIds, allSalesmen]);
  const salesmanOptions = useMemo(() => {
    const options = salesman?.map(({ id, code }) => ({ label: code, value: id })) || [];
    options.unshift({
      label: 'All Salesmen',
      value: 'allSalesmen',
    });
    return options;
  }, [salesman]);
  const [salesmanFilterSelected, setSalesmanFilterSelected] = useState(
    Boolean(salesmanFilterValues?.length)
  );
  const handleSalesmanFilterChange = (vals) => {
    const allSalesmenSelected = vals ? vals.find((val) => val.value === 'allSalesmen') : false;
    if (allSalesmenSelected) {
      handleChange('allSalesmen')(true);
      handleChange('salesmanIds')([]);
    } else {
      handleChange('allSalesmen')(false);
      handleChange('salesmanIds')(vals ? vals.map((val) => val.value) : []);
    }
  };

  const channelFilterValues = useMemo(() => {
    if (allChannels) {
      return [{ label: 'All Channels', value: 'allChannels' }];
    } else {
      return channelIds?.map((id) => byId(channelOptions, id, 'value') || {}) || [];
    }
  }, [channelIds, allChannels]);
  const [channelFilterSelected, setChannelFilterSelected] = useState(
    Boolean(channelFilterValues.length)
  );
  const handleChannelFilterChange = (vals) => {
    const allChannelsSelected = vals ? vals.find((val) => val.value === 'allChannels') : false;
    if (allChannelsSelected) {
      handleChange('allChannels')(true);
      handleChange('channelIds')([]);
    } else {
      handleChange('allChannels')(false);
      handleChange('channelIds')(vals ? vals.map((val) => val.value) : []);
    }
  };

  const valFilterSplit = valueFilter.split(',');
  const valFilter = valFilterSplit.length > 0 ? valFilterSplit[0] : '';
  const [valueFilterSelected, setValueFilterSelected] = useState(Boolean(valFilter));
  const val1 = valFilterSplit.length > 1 ? valFilterSplit[1] : '';
  const val2 = valFilterSplit.length > 2 ? valFilterSplit[2] : '';

  const handleValueFilterChange = (type) => (e) => {
    if (type === 0) {
      handleChange('valueFilter')([e.target.value, val1, val2].join(','));
    } else if (type === 1) {
      handleChange('valueFilter')([valFilter, e.target.value, val2].join(','));
    } else {
      handleChange('valueFilter')([valFilter, val1, e.target.value].join(','));
    }
  };

  const onAccordionClick = (accId) => (e, expanded) => {
    if (expanded) {
      dispatch([a.CHANGE_ACCORDION_ID, accId]);
    } else {
      dispatch([a.CHANGE_ACCORDION_ID, -1]);
    }
  };

  const layers = useMemo(() => {
    const polygon = new PolygonLayer({
      id: 'polygonLayer',
      pickable: true,
      stroked: true,
      filled: true,
      wireframe: true,
      lineWidthMinPixels: 1,
      data: exclusiveZones?.features?.[0]?.geometry?.coordinates ?? [],
      getPolygon: (d) => d,
      getElevation: 2,
      getLineColor: [60, 60, 60],
      getFillColor: [95, 95, 95, 100],
      getLineWidth: 50,
    });
    return [polygon];
  }, [exclusiveZones]);

  const initialViewState = useMemo(
    () =>
      exclusiveZones?.features?.[0]?.geometry?.coordinates && {
        pitch: 0,
        bearing: 0,
        latitude: exclusiveZones?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[0] || 0,
        longitude: exclusiveZones?.features?.[0]?.geometry?.coordinates?.[0]?.[0]?.[1] || 0,
        zoom: 7,
      },
    [exclusiveZones]
  );
  const [viewport, setViewPort] = useState(initialViewState);

  const mapRef = useRef();

  return (
    <>
      <ConfirmationDialog
        ref={deleteConfirmDialog}
        title="Delete Plan Profile"
        onYes={() => apiAction(deleteProfile(id, branchId), () => dispatch([a.DELETE_PROFILE, id]))}
      />
      <Card className={classes.card} elevation={createDialog ? 0 : 1}>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 0}
          onChange={onAccordionClick(0)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel1"
          >
            <Typography className={classes.heading}>General</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item xs={8}>
                  <Grid
                    container
                    className={classes.sectionHeading}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <TextField
                        className={classes.name}
                        label={'Profile Name'}
                        variant="outlined"
                        onChange={(e) => handleChange('name')(e.target.value)}
                        // defaultValue={name || 'Default'}
                        value={name}
                        helperText={!name && 'Profile name is empty'}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.description}
                        label={'Profile Description'}
                        multiline
                        rows={3}
                        variant="outlined"
                        onChange={(e) => handleChange('description')(e.target.value)}
                        // defaultValue={name || 'Default'}
                        value={description}
                        helperText={!description && 'Profile description is empty'}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={'Profile Sequence'}
                        variant="outlined"
                        onChange={(e) => handleChange('sequence')(e.target.value)}
                        // defaultValue={name || 'Default'}
                        value={sequence}
                        helperText={!sequence && 'Profile sequence is empty'}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        options={['Circular', 'Linear', 'OneWay']}
                        onChange={(_, e) => handleChange('routeOption')(e)}
                        value={routeOption}
                        openOnFocus
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: 210, marginTop: 20 }}
                            label="Route Option"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container justify="space-between" alignContent="center" direction="column">
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={def}
                            disabled={!def && disableDefault}
                            onChange={() => handleChange('default')(!def)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Default"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isStatic}
                            onChange={() => handleChange('static')(!isStatic)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Static"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoPlan}
                            onChange={() => handleChange('autoPlan')(!autoPlan)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Auto Plan"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoFreeze}
                            onChange={() => handleChange('autoFreeze')(!autoFreeze)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Auto Freeze"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoLearning}
                            onChange={() => handleChange('autoLearning')(!autoLearning)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Auto Learning"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isDummy}
                            onChange={() => handleChange('isDummy')(!isDummy)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Dummy Profile"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isMultiTrip}
                            onChange={() => handleChange('isMultiTrip')(!isMultiTrip)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Multi - Trip"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isInterleaved}
                            onChange={() => handleChange('isInterleaved')(!isInterleaved)}
                            color="primary"
                          />
                        }
                        className={classes.default}
                        label="Interleaved"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 1}
          onChange={onAccordionClick(1)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel2"
          >
            <Typography className={classes.heading}>Profile Filters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              className={classes.section}
              alignItems="space-between"
              direction="column"
              wrap="nowrap"
              spacing={2}
            >
              <Grid item xs={6} className={classes.controlC}>
                <div class="grid grid-cols-2 gap-4 m-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={salesmanFilterSelected}
                        color="primary"
                        onChange={() => {
                          if (salesmanFilterSelected) {
                            dispatch([a.EDIT, { id, key: 'salesmanIds', value: [] }]);
                            dispatch([a.EDIT, { id, key: 'allSalesmen', value: false }]);
                          }
                          setSalesmanFilterSelected(!salesmanFilterSelected);
                        }}
                      />
                    }
                    label="Salesman"
                  />
                </div>
                <div class="m-2">
                  <Collapse in={salesmanFilterSelected} timeout="auto" unmountOnExit>
                    <ReactSelect
                      placeholder="Select Salesmen"
                      isMulti
                      closeMenuOnSelect={false}
                      components={animatedComponent}
                      onChange={handleSalesmanFilterChange}
                      value={salesmanFilterValues}
                      options={salesmanOptions}
                    />
                    {/* <ReactSelect
                            menuPortalTarget={document.getElementById('portal-target')}
                        /> */}
                  </Collapse>
                </div>
              </Grid>
              <Grid item>
                <div class="grid grid-cols-2 gap-4 m-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={valueFilterSelected}
                        onChange={() => {
                          if (valueFilterSelected) {
                            dispatch([a.EDIT, { id, key: 'valueFilter', value: '' }]);
                          }
                          setValueFilterSelected(!valueFilterSelected);
                        }}
                        color="primary"
                      />
                    }
                    label="Value"
                  />
                </div>
                <div class="m-2">
                  <Collapse in={valueFilterSelected} timeout="auto" unmountOnExit>
                    <Grid container alignItems="flex-end" spacing={2}>
                      {valFilter === 'btw' ? (
                        <Grid item xs={2}>
                          {' '}
                          <TextField
                            label="value"
                            value={val2}
                            onChange={handleValueFilterChange(2)}
                            type="number"
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={2}>
                        <FormControl className={classes.selectControl}>
                          <InputLabel htmlFor="valueFilter">Filter Option</InputLabel>
                          <Select
                            style={{ width: 150 }}
                            fullWidth
                            value={valFilter}
                            onChange={handleValueFilterChange(0)}
                            inputProps={{
                              name: 'valueFilter',
                              id: 'valueFilter',
                            }}
                          >
                            <MenuItem value={'lt'}>Less Than</MenuItem>
                            <MenuItem value={'gt'}>Greater Than</MenuItem>
                            <MenuItem value={'btw'}>Between</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        {valFilterSplit.length > 1 ? (
                          <TextField
                            label="value"
                            value={val1}
                            onChange={handleValueFilterChange(1)}
                            type="number"
                            variant="outlined"
                            margin="dense"
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </Collapse>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.controlC}>
                <div class="grid grid-cols-2 gap-4 m-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={channelFilterSelected}
                        onChange={() => {
                          if (channelFilterSelected) {
                            dispatch([a.EDIT, { id, key: 'channelIds', value: [] }]);
                            dispatch([a.EDIT, { id, key: 'allChannels', value: false }]);
                          }
                          setChannelFilterSelected(!channelFilterSelected);
                        }}
                        color="primary"
                      />
                    }
                    label="Channels"
                  />
                </div>
                <div class="m-2">
                  <Collapse in={channelFilterSelected} timeout="auto" unmountOnExit>
                    <ReactSelect
                      placeholder="Select channels"
                      isMulti
                      closeMenuOnSelect={false}
                      components={animatedComponent}
                      onChange={handleChannelFilterChange}
                      value={channelFilterValues}
                      options={channelOptions}
                    />
                    {/* <ReactSelect
                            menuPortalTarget={document.getElementById('portal-target')}
                        /> */}
                  </Collapse>
                </div>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 2}
          onChange={onAccordionClick(2)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel3"
          >
            <Typography className={classes.heading}>Customer {'&'} Order Constraints</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid className={classes.constraintsSection} container justify="space-between">
              <Grid direction="column" container xs={6}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={['Customer', 'Invoice', 'Line', 'Line Split']}
                    onChange={(_, e) => handleChange('planningLevel')(e)}
                    value={planningLevel}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: 250 }}
                        margin="dense"
                        label="Planning Level"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs={2}>
                      <Autocomplete
                        options={['Order Priority', 'Value', 'Delivery Date', 'Customer Priority']}
                        onChange={(_, e) => handleChange('orderPriorityCriteria')(e)}
                        value={orderPriorityCriteria}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: 250 }}
                            margin="dense"
                            label="Order Priority Criteria"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {(orderPriorityCriteria === 'Value' ||
                      orderPriorityCriteria === 'Delivery Date') &&
                    orderPriorityCriteria ? (
                      <Grid item xs={2}>
                        <Autocomplete
                          options={['Ascending', 'Descending']}
                          onChange={(_, e) => handleChange('orderPrioritySortType')(e)}
                          value={orderPrioritySortType}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ minWidth: 150 }}
                              margin="dense"
                              label="Sort Type"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column" xs={6} spacing={2}>
                <Grid item xs={6} className={classes.controlC}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useDelWindow}
                        onChange={() => handleChange('useDelWindow')(!useDelWindow)}
                        color="primary"
                      />
                    }
                    label="Use Delivery Window"
                  />
                </Grid>
                <Grid item xs={6} className={classes.controlC}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useVehCompat}
                        onChange={() => handleChange('useVehCompat')(!useVehCompat)}
                        color="primary"
                      />
                    }
                    label="Use Vehicle Compatibility"
                  />
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 3}
          onChange={onAccordionClick(3)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel4"
          >
            <Typography className={classes.heading}>Fleet Cost {'&'} Constraints</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.section}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.vehicleHeader}
              >
                <Grid item className={classes.vehicleLabel}>
                  <Typography variant="h6" color="textSecondary">
                    Vehicles
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      dispatch([a.ADD_VEHICLE, id]);
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              {vehicleTypes.map((vehicle, index) => (
                <Vehicle
                  key={index}
                  vehicle={vehicle}
                  dispatch={dispatch}
                  profileId={id}
                  vehicleIndex={index}
                />
              ))}
            </div>
            <Grid xs={2}>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="routingProfile">Routing Profile</InputLabel>
                <Select
                  value={routingProfile}
                  defaultValue={'distance'}
                  inputProps={{
                    name: 'routingProfile',
                    id: 'routingProfile',
                  }}
                  onChange={(e) => handleChange('routingProfile')(e.target.value)}
                >
                  <MenuItem value={'distance'}>Distance</MenuItem>
                  <MenuItem value={'bike'}>Time - Bike</MenuItem>
                  <MenuItem value={'van'}>Time - Van</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 5}
          onChange={onAccordionClick(5)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel6"
          >
            <Typography className={classes.heading}>Service Time Model</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={4} className={classes.controlC}>
                <div>
                  <p class="text-xs font-medium text-secondary ml-1">Order Service Type</p>
                  <ReactSelect
                    placeholder="Order Service Type"
                    options={orderServiceTypeOptions}
                    isMulti
                    value={serviceTypes?.map((st) => byId(orderServiceTypeOptions, st, 'value'))}
                    onChange={(val) =>
                      handleChange('serviceTypes')(val ? val.map((e) => e.value) : [])
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} className={classes.controlC}>
                <FormControl className={classes.selectControl}>
                  <InputLabel htmlFor="serviceTimeModel">Service Time Model</InputLabel>
                  <Select
                    value={serviceTimeModel}
                    inputProps={{
                      name: 'serviceTimeModel',
                      id: 'serviceTimeModel',
                    }}
                    onChange={(e) => handleChange('serviceTimeModel')(e.target.value)}
                  >
                    <MenuItem value={'linear'}>Linear</MenuItem>
                    <MenuItem value={'provided'}>Provided</MenuItem>
                  </Select>
                </FormControl>
                <Collapse in={serviceTimeModel === 'linear'} timeout="auto" unmountOnExit>
                  <JsonEditor value={data} onChange={(val) => handleChange('data')(val)} />
                </Collapse>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 6}
          onChange={onAccordionClick(6)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel7"
          >
            <Typography className={classes.heading}>Exclusive Zones</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={6}>
                <JsonEditor
                  value={exclusiveZones ?? {}}
                  onChange={(val) => handleChange('exclusiveZones')(val)}
                />
              </Grid>
              {exclusiveZones?.type ? (
                <Grid item xs={6} style={{ height: '100%', width: '50%', position: 'relative' }}>
                  <DeckGL
                    controller
                    layers={[layers]}
                    initialViewState={viewport}
                    viewState={initialViewState}
                  >
                    <ReactMapGL
                      {...viewport}
                      ref={mapRef}
                      onViewportChange={setViewPort}
                      mapboxApiAccessToken="pk.eyJ1Ijoic3RhY2tib3giLCJhIjoiY2sxZ3E1NzkxMGUzYjNjcXAwd3E1MTU4OSJ9.BhT5mC_uhfKP-6VWnHsOxA"
                      mapStyle="mapbox://styles/mapbox/streets-v11"
                    />
                  </DeckGL>
                </Grid>
              ) : null}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.accordion}
          expanded={accordionId === 4}
          onChange={onAccordionClick(4)}
        >
          <ExpansionPanelSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            id="expansionPanel5"
          >
            <Typography className={classes.heading}>Warehouse Constraints</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid className={classes.facilitySection} container direction="column" spacing="2">
              <Grid item>
                <TextField
                  fullWidth
                  label="Facility Start Time (HH:MM)"
                  variant="outlined"
                  onChange={(e) => handleChange('startTime')(e.target.value)}
                  value={startTime}
                  margin="dense"
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      label="Facility Throughput Cap."
                      value={parseInt(deliveryWeightConstraint)}
                      onChange={(e) =>
                        handleChange('deliveryWeightConstraint')(
                          e.target.value ? parseFloat(e.target.value) : null
                        )
                      }
                      type="number"
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      options={['KG', 'Orders', 'HU']}
                      onChange={(_, e) => handleChange('deliveryCapacityUnit')(e)}
                      value={deliveryCapacityUnit}
                      openOnFocus
                      forcePopupIcon={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: 100 }}
                          margin="dense"
                          label="Units"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Autocomplete
                  options={profilesName && profilesName.filter((pName) => pName !== name)}
                  onChange={(_, e) => handleChange('wave1Profile')(e)}
                  value={wave1Profile}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: 250 }}
                      margin="dense"
                      label="Wave 1 Profile"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {!isNew && (
          <CardActions className={classes.cardActionPanel}>
            <Button color="secondary" onClick={() => deleteConfirmDialog.current.openDialog()}>
              Delete
            </Button>
            <Button
              color="primary"
              disabled={!ui.dirty}
              onClick={() =>
                apiAction(updateProfile(id, branchId, profile), (updatedProfile) =>
                  dispatch([
                    a.REPLACE_PROFILE,
                    {
                      replaceProfile: id,
                      updatedProfile,
                    },
                  ])
                )
              }
            >
              Save
            </Button>
            <Button
              className={classes.copyProfileButton}
              onClick={() => {
                dispatch([
                  a.COPY_THIS_TO_NEW,
                  {
                    profileId: id,
                  },
                ]);
                openDialog();
              }}
            >
              NEW COPY
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}

const useStylesVehicles = makeStyles((theme) => ({
  smallTextField: {
    minWidth: 75,
  },
  bigTextField: {
    minWidth: 120,
  },
  vehicleRow: {
    maxWidth: '90vw',
    overflow: 'auto',
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  vehrowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  deleteIcon: {
    marginTop: 20,
    flex: '0 0',
    minWidth: 50,
  },
}));

//To convert speed and time unit(km/h and hours)
const unitConversion = (key, value) =>
  key === 'maxTripTime'
    ? Math.round((value / 3600) * 100) / 100
    : key === 'volumeLimitRatio'
    ? Math.round(value * 100 * 10) / 10
    : key === 'loadingTime' || key === 'serviceTime'
    ? value / 60
    : key === 'maxDistance'
    ? value / 1000
    : value;

//To re-convert speed and time unit(from km/h and hours to m/s and s)
const reverseUnitConversion = (key, value) =>
  key === 'maxTripTime'
    ? value * 3600
    : key === 'volumeLimitRatio'
    ? value / 100
    : key === 'loadingTime' || key === 'serviceTime'
    ? value * 60
    : key === 'maxDistance'
    ? value * 1000
    : value;

function Vehicle({ vehicle, dispatch, profileId, vehicleIndex }) {
  const classes = useStylesVehicles();

  const vehicleDataOrder = {
    name: null,
    type: null,
    fixedCost: null,
    fixedCostMaxDistance: null,
    variableCost: null,
    variableCostUnit: null,
    maxDropPoints: null,
    maxTrips: null,
    maxTripTime: null,
    minUtilization: null,
    volumeLimitRatio: null,
    maxVolume: null,
    maxWeight: null,
    vehicleCount: null,
    contractType: null,
    defaultSpeed: null,
    notionalCostFactor: null,
    maxHUs: null,
    maxDistance: 0,
    loadingTime: 0,
    serviceTime: 0,
    isSplitCompatible: false,
  };
  const orederedVehicleData = Object.assign(vehicleDataOrder, vehicle);
  const handleInputChange = (key, value) => {
    let newValue = value;
    if (key === 'isSplitCompatible') {
      newValue = value;
    }
    dispatch([
      a.EDIT_VEHICLE,
      {
        profileId,
        vehicleIndex,
        key,
        value: newValue,
      },
    ]);
  };
  return (
    <Grid container>
      <Grid item xs={11}>
        <Grid
          container
          className={classes.vehicleRow}
          justify="space-between"
          alignItems="flex-start"
          spacing={1}
          wrap="nowrap"
        >
          {Object.entries(orederedVehicleData)
            .filter(([key, value]) => !['id', 'avgSpeed'].includes(key) && value !== null)
            .map(([key, value]) => (
              <Grid
                item
                key={key}
                className={vpl[key]?.isSmall ? classes.smallTextField : classes.bigTextField}
                style={{ flex: '1 0 auto' }}
              >
                {key === 'isSplitCompatible' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value} // Ensure the checkbox value is a boolean
                        onChange={(e) => handleInputChange(key, e.target.checked)}
                        color="primary"
                      />
                    }
                    label={vpl[key]?.label}
                  />
                ) : vpl[key]?.options ? (
                  <Autocomplete
                    options={vpl[key].options}
                    onChange={(_, e) =>
                      dispatch([
                        a.EDIT_VEHICLE,
                        {
                          profileId,
                          vehicleIndex,
                          key,
                          value: e,
                        },
                      ])
                    }
                    value={value || ' '}
                    openOnFocus
                    forcePopupIcon={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            marginTop: 15,
                          },
                        }}
                        label={vpl[key]?.label}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    inputProps={{
                      style: {
                        marginTop: 15,
                      },
                    }}
                    label={vpl[key]?.label}
                    //value={value || ' '}
                    value={unitConversion(key, value) || ' '}
                    type={vpl[key]?.isText ? 'text' : 'number'}
                    onChange={(e) =>
                      dispatch([
                        a.EDIT_VEHICLE,
                        {
                          profileId,
                          vehicleIndex,
                          key,
                          value: `${reverseUnitConversion(key, e.target.value)}`,
                        },
                      ])
                    }
                  />
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={1} className={classes.deleteIcon}>
        <IconButton
          color="secondary"
          className={classes.button}
          component="span"
          onClick={() =>
            dispatch([
              a.DELETE_VEHICLE,
              {
                profileId,
                vehicleIndex,
              },
            ])
          }
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
